import React from 'react'
import Layout from '../layouts/GenericLayout'
import pic11 from '../assets/images/pic11.jpg'
import PageSection from '../components/PageSection';


import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../helpers';


const Generic = () => <I18n>
    {(t, {i18n}) => {
    return <Layout>
            <PageSection>
                <header className="major">
                    <h1>O firme - Ferencz Racing Technology</h1>
                </header>
                <p>Firma FRT – Ferencz Racing Technology bola založená jej majiteľom Jozefom Ferenczom v roku 1989 v Košiciach. Podnikanie firmy v jej začiatkoch bolo zamerané na presnú malosériovú výrobu strojárskych súčiastok, výrobu a údržbu strojov a technológií a zákazkovú výrobu súčiastok používaných v motoristickom športe a stavbe súťažných áut. 
                </p>
                <p>Personálne obsadenie a technologické vybavenie sa postupom času zameralo na vývoj, výrobu, nastavenie a servis tlmičov (Shock absorbers, Suspensions, Dämpfer) FRT a iných popredných svetových značiek Öhlins, Sachs, Penske, Boss, Reiger, Donerre, Proflex, Fox, King... , ktoré sú používané v mnohých motoristických disciplínach Dakar series, CCR-Crosscountry rally, Rally, Okruhy-circuit, Octavia Cup, Kopce-Hillclimb a iných. 
                </p>
                <p>Vývoj produktov konštrukcia, montáž prebieha tzv. pod jednou strechou s minimálnym počtom kooperácií, čo umožňuje vysokú flexibilitu v reťazci vývoj-konštrukcia-výroba-montáž-testovanie-závod-servis, čo nám pomáha skrátiť čas a náklady potrebné pri optimalizácií tlmiča. 
                </p>
                <p>V roku 2007 firma FRT získala zastúpenie autorizovaného dealera firmy  pre Slovenskú republiku. 
                </p>
                <p>Pri výrobe tlmičov používame materiály od svetových výrobcov:</p>
                <ul>
                  <li>
                  Oceľové dielce – Böhler-Udeholm
                  </li>
                
                  <li>
                  Hliníkové zliatiny – Alcan Aluminium
                  </li>


                  <li>Tesnenia – Trelleborg</li>
                  <li>Olej – Silkolene, Fuchs</li>
                </ul>
                
                <p>Firma v rámci inžiniersko-technickej činnosti zabezpečuje:</p>
                <ul>
                <li>Vyhotovenie výrobnej dokumentácie podľa zadaných projektov alebo návrhov zákazníka, rozkreslenie uzlov.</li>
                <li>Prekreslenie starej dokumentácie do 2D alebo 3D elektronickej podoby a zakreslenie dokumentácie skutkového stavu.</li>
                <li>Konštrukčný vývoj a výrobu prototypov pre opakovanú a sériovú výrobu.</li>
                <li>Konštrukčný vývoj a výrobu manipulátorov a manipulačných zariadení s elektrickým a pneumatickým pohonom.</li>
                <li>Konštrukciu, výrobu, dodávku zariadení charakteru kusovej a malosériovej výroby podľa špecifických požiadaviek zákazníka.</li>
                <li>Vypracovanie technickej dokumentácie vo forme NC programov.</li>
                <li>Kontrolu našich výrobkov vykonávame na meracích prístrojoch značky Carl Zeiss v spolupráci s TU Košice.</li>
                </ul>
                  Tešíme sa na spoluprácu s novými tímami a jazdcami pri dosahovaní cenných úspechov čo je pre nás zároveň ďalšou výzvou pri zdokonaľovaní našich produktov. 
            
            
            </PageSection>
    </Layout>
}
}
</I18n>

export default withI18next()(Generic)

export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`